.panel-enter {
    opacity: 0;
    transform: scale(0.975);
  }
  .panel-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
  }
  .panel-exit {
    opacity: 1;
  }
  .panel-exit-active {
    opacity: 0;
    transform: scale(0.975);
    transition: opacity transform 200ms;
  }