.versioning-background {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    background-color: rgba(1, 1, 1, .5);
    z-index: 2147483002; /* 1 more that the help button*/
}

.versioning-modal {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-color: white;
    box-shadow: 0px -4px 15px rgba(145, 157, 170, 0.188319);
    border-radius: 25px 25px 0 0;
}

.versioning-modal-title{
    margin: 24px;
    text-align: left;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #222B45;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.versioning-modal-title-right{
    display: flex;
    flex-direction: column;
}

.versioning-modal-title-left{
    display: flex;
    flex-direction: column;
}

.versioning-modal-content-svg{
    margin: 50px auto 30px auto;
}

.versioning-modal-content{
    background-color: #FAF6ED;
    position: absolute;
    width: 100%;
    height: 100%;
    padding:auto
}

.versioning-modal-content-text{
    margin: 0 auto 0 auto;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #071537;
}