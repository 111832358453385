input,
input:focus,
input:active {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    outline: none;
}

div,
div:hover,
div:active,
a,
button,
input,
button:hover,
input:hover,
a:hover,
a:active,
button:active,
input:active {
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
a {
    -webkit-user-select: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: inherit;
}
